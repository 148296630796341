<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
      <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
        <!-- Email Accounts -->
        <template v-for="(lk_acc, idx) in linked_accounts">
          <validation-provider
            :key="idx"
            #default="validationContext"
            :name="$t('fields.email')"
            
          >
            <b-form-group
              :label="`${$t('fields.email')} #${idx+1}`"
              :label-for="`linked_accounts[${idx}]`"
            >
              <b-input-group>
                <b-form-input
                  :id="`linked_accounts[${idx}]`"
                  v-model="linked_accounts[idx].email"
                  autofocus
                  :state="linked_accounts[idx].validated && getValidationState(validationContext)"
                  trim
                  placeholder=""
                />
                <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      v-b-tooltip.hover.bottom="$t('forms.delete_model', {model:$t('fields.email')})"

                      style="color: tomato"
                      icon="TrashIcon"
                      @click="deleteFamAcc(idx)"
                    />
                  </b-input-group-append>
                </b-input-group>
                <span style="color:#ea5455; font-size: 0.857rem; width:100%;">
                    {{ !linked_accounts[idx].validated ? validationErrorMessage : ''}}
                  </span>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          </template>
          <div>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="link"
              class="mb-1"
              @click="AddLinkedAccountField"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-75"
              /> {{ $t('forms.add_model', {model: $t('fields.email')})  }}
            </b-button>
          </div>

 
      <!-- Action Buttons -->
      <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          type="button"
          @click="submitChanges"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          {{ $t('forms.save_changes') }}
        </b-button>
        <b-button
          variant="outline-secondary"
          type="button"
          @click="hasHistory() 
            ? $router.go(-1) 
            : $router.push({ name: 'group-view', 
              params: { id: group.id, } })"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          {{ $t('forms.go_back') }}
        </b-button>

        </b-form>
      </validation-observer>

      </b-col>

    </b-row>   
  </div>
</template>

<script>
import { editGroup } from '@/api/routes'

import {
  BInputGroup, BInputGroupAppend,VBTooltip,
  BButton, BRow, BCol, BFormGroup,BFormInvalidFeedback, BFormInput, BForm, BCard, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components:{  
    vSelect,
    ValidationProvider, ValidationObserver,
    BInputGroup, BInputGroupAppend,
    BButton, BRow, BCol, BFormGroup,BFormInvalidFeedback, BFormInput, BForm, BCard, BCardHeader, BCardTitle,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  setup(props) {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      // form validation
      refFormObserver,
      getValidationState,
    }
  },
  props:{
    group: {
      type: Object,
      required: true,
    },
    tutors: {
      type: Array,
      required: true
    }
  },
  data(){
    return {
      required,
      linked_accounts:[],
      validationErrorMessage:''

    }
  },
  mounted(){
    let lu = []
    this.tutors.map(x=> {
      let t = {
        validated: true,
        email: x.email
      }
      lu.push(t)
      
    })
    if(lu.length == 0){
      lu.push({
        email:'',
        validated:true
      })
    }
    this.linked_accounts = lu
  },
  methods:{
    deleteFamAcc(idx){
      this.linked_accounts.splice(idx, 1);
    },
    AddLinkedAccountField(){
      this.linked_accounts.push({
        email:'',
        validated:true
      })
    },
    hasHistory () { 
       return window.history.length > 2 
    },
    async submitChanges(){
      let arr_no_empty = this.linked_accounts.filter(element => element.email != '');
      let arrUniq = [...new Map(arr_no_empty.map(v => [v.email, v])).values()]
        let data = {
            'id': this.group.id,
            'linked_users': arrUniq,
            'tab':'tutors',
        } 
        await this.$http.put(editGroup+'/'+this.group.id, data).then( response => {
          if(response.data.status == 200){
            this.validationErrorMessage = '';
            this.$emit('refetch-data')
            this.linked_accounts = response.data.linked_accounts
            this.makeToast('success', this.$t('Success'), this.$t('message.'+response.data.msg_code));
          } else if(response.data.msg_code == 'ERR_GROUP_1003'){
            this.makeToast('danger', this.$t('Error'),this.$t('message.'+response.data.msg_code));
            let lk_acc = []
            this.linked_accounts.map(x=> {
              let lk = {
                email: x.email,
                validated: true
              }
              if(x.email == response.data.license.email){
                lk.validated = false; 
              }
              lk_acc.push(lk)
            })
            this.linked_accounts = lk_acc;
            this.validationErrorMessage = this.$t('message.'+response.data.msg_code)
          } else {
            this.validationErrorMessage = this.$t('message.'+response.data.msg_code)
            this.linked_accounts = response.data.linked_accounts
            this.makeToast('danger', this.$t('Error'),this.$t('message.'+response.data.msg_code));
          }
        }).catch(error => {
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
        })
      
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>