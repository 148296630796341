<template>
  <div>
    <validation-observer
      ref="refFormObserver"
      #default="{ handleSubmit }"
    >
      <!-- User Info: Input Fields -->
      <b-form
        @submit.prevent="handleSubmit(submitChanges)"
        @reset.prevent="resetForm"
      >
        <b-row class="mb-2">
          <b-col>
            <b-avatar
              rounded
              size="70px"
              class="mr-2"
              :src="require('@/assets/images/avatar/groups/' + (group.id_avatar || 'null') + '.png?v4')"

            />
            <b-button
              :variant="!showAvatarOptions?'primary':'secondary'"
              @click="showAvatarOptions = !showAvatarOptions"
            >
              {{ showAvatarOptions ? $t('table.hide') : $t('table.change')}}
            </b-button>
          </b-col>
        </b-row>
        <b-row v-if="showAvatarOptions">
          <b-col>
            <avatar-modal-selector
                :avatar_id="group.id_avatar"
                @set-avatar="setAvatar"
                @toggle-edit-avatar="showAvatarOptions = false"
            ></avatar-modal-selector>
          </b-col>
        </b-row>
        <b-row>
          <!-- Full Name -->
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('fields.name')"
              rules="required|max:255"
              :custom-messages="{ required: $t('validators.required') }"
            >
              <b-form-group
                :label="$t('fields.name')"
                label-for="name"
              >
              <b-form-input
                id="name"
                v-model="inputName" 
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="1º group"
                @keydown.enter.prevent
              />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <!-- Form Actions -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          type="submit"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          {{ $t('forms.save_changes') }}
        </b-button>
        <b-button
          variant="outline-secondary"
          type="button"
          @click="hasHistory() 
            ? $router.go(-1) 
            : $router.push({ name: 'group-view', 
              params: { id: group.id, } })"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          {{ $t('forms.go_back') }}
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { editGroup } from '@/api/routes'

import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup,BFormInvalidFeedback, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { max } from 'vee-validate/dist/rules';
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import AvatarModalSelector from './AvatarModalSelector.vue'

export default {
  components:{
    AvatarModalSelector,
    
    BButton, BMedia, BAvatar, BRow, BCol, BFormGroup,BFormInvalidFeedback, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle,
    ValidationProvider, ValidationObserver,
  },
  setup(props) {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      // form validation
      refFormObserver,
      getValidationState,
    }
  },
  props:{
    group: {
      type: Object,
      required: true,
    },
  },
  created(){
    extend('required', {
      ...required,
      message: this.$t('forms.errors.required'),
    });
    extend('max', {
      ...max,
      message: (field, { length }) => this.$t('forms.errors.max_characters', { length })
    });
  },
  data(){
    return {
      required,
      inputName: this.group.name, 
      showAvatarOptions:false,
    }
  },
  methods:{
    hasHistory () { 
       return window.history.length > 2 
    },
    setAvatar(id){
        this.group.id_avatar = id;
    },
    async submitChanges(){
      this.group.name = this.inputName;
      let data = {
          'id': this.group.id,
          'name':this.group.name,
          'id_avatar':this.group.id_avatar,
          'tab':'main',
      }
      await this.$http.put(editGroup+'/'+this.group.id, data).then( response => {
        if(response.data.status == 200){
          this.$emit('refetch-data')
          this.makeToast('success', this.$t('Success'), this.$t('message.'+response.data.msg_code));
        } else {
          this.makeToast('danger', this.$t('Error'), this.$t('message.'+response.data.msg_code));
        }
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
      })
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  }
}
</script>

<style>

</style>